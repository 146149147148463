import React from "react"
import { navigate } from "gatsby"
import { useAmIAWorker } from "../../contexts/AmIAWorkerContext"
import { useForm } from "react-hook-form"
import { useSpring, animated, config } from "react-spring"

import { questionList, Radio } from "../../components/amiaworker/questions"
import map from "lodash/map"
import AmIAWorkerLayout from "../../components/amiaworker/layout"

export default function AmIAWorkerQuestion13({ data, location }) {
  const seo = {
    metaTitle: "Am I a worker",
    metaDescription:
      "Find out if you are a worker, self-employed or an employee",
    url: location.pathname,
  }
  const { setValues, amIAWorker } = useAmIAWorker()
  const currentPage = 13
  const nextPage = currentPage + 1
  const previousPage = currentPage > 1 ? currentPage - 1 : 1
  const { handleSubmit, errors, register, formState } = useForm({
    defaultValues: {
      [questionList[currentPage].name]:
        amIAWorker?.[questionList[currentPage].name],
    },
    mode: "onChange",
  })
  const { isDirty, isValid } = formState

  const onSubmit = data => {
    navigate(`/amiaworker/results/`)
    setValues(data)
  }

  const goBack = data => {
    navigate(`/amiaworker/question${previousPage}/`)
    if (isValid) {
      handleSubmit(updateData)()
    }
  }

  const updateData = data => {
    setValues(data)
  }

  const questionListAnswers = map(
    questionList[currentPage].answers,
    (answer, i) => (
      <label className="flex mb-8 cursor-pointer" key={i}>
        <Radio
          type="radio"
          name={questionList[currentPage].name}
          value={i}
          ref={register({
            required: true,
          })}
        />
        <span class="ml-2 font-bold">{answer.text}</span>
      </label>
    )
  )

  const cfg = {
    config: config.gentle,
    from: {
      transform: "perspective(700px) translate3d(0, -15px, -15px)",
      opacity: 0.7,
    },
    to: { transform: "perspective(700px) translate3d(0, 0, 0)", opacity: 1.0 },
  }

  const springAnimation = useSpring(cfg)

  return (
    <AmIAWorkerLayout
      seo={seo}
      currentPage={currentPage}
      previousPage={previousPage}
    >
      <animated.div style={springAnimation}>
        <div className="rounded-md p-8 shadow-xl h-full bg-white overflow-hidden justify-between border-gray-50 border md:max-w-screen-lg">
          <div className="block text-md font-bold mb-8 text-white bg-green-500 p-4 -m-8 md:text-2xl">
            {questionList[currentPage].question}
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            {questionListAnswers}
            <button
              type="button"
              onClick={goBack}
              className=" border-gray-300 text-gray-300 font-bold text-md text-center border-4  rounded-md py-2 px-4 md:px-8 transition duration-500 ease select-none hover:text-white hover:bg-pink-800 focus:outline-none hover:border-pink-800 focus:shadow-outline self-start"
            >
              Back
            </button>
            <input
              type="submit"
              value="Next"
              className="cursor-pointer	 disabled:opacity-20 float-right	 border-4 border-pink-800 text-white font-bold text-md text-center bg-pink-800 rounded-md py-2 px-4 md: px-8 transition duration-500 ease select-none hover:text-white hover:bg-green-500 focus:outline-none hover:border-green-500 focus:shadow-outline self-start"
              disabled={!isValid}
            />
          </form>
        </div>
      </animated.div>
    </AmIAWorkerLayout>
  )
}
